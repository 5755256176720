import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import LayoutWebsite from './layout/layout.website';
import HomePage from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import EconomicCalender from './pages/EconomicCalender';
import ForexNews from './pages/ForexNews';
import Leverage from './pages/Leverage';
import ForexPage from './pages/Forex';
import IndicesPage from './pages/Indices';
import MetalsPage from './pages/Metals';
import EnergiesPage from './pages/Energies';
import CryptoPage from './pages/Crypto';
import StocksPage from './pages/Stocks';
import Spreads from './pages/Spreads';
import Cashback from './pages/Cashback';
import BrokerBonus from './pages/BrokerBonus';
import TradingBonus from './pages/TradingBonus';
import ZeroCommission from './pages/ZeroCommission';
import TradeDemo from './pages/TradeDemo';
import InviteAFriend from './pages/InviteAFriend';
import AccountTransfer from './pages/AccountTransfer';
import Vip from './pages/Vip';
import LoginPage from './pages/Login';
import OpenAccount from './pages/OpenAccount';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import CookiePolicy from './pages/CookiePolicy';
import AMLPolicy from './pages/AmlPolicy';
import { SwapRates } from './pages/SwapRates';
import { TradingHours } from './pages/TradingHours';
import { HolidayTradingHours } from './pages/HolidayTradingHours';
import { Calculator } from './pages/Calculator';
import { Accounts } from './pages/Accounts';
import { VideoTutorial } from './pages/VideoTutorial';
import { EBooksPage } from './pages/EBooksPage';
import { AboutUs } from './pages/AboutUs';
import { LicenseAndRegistration } from './pages/LicenseAndRegistration';
import { Documentation } from './pages/Documentation';
import { FAQ } from './pages/FAQ';
import { ContactUs } from './pages/ContactUs';
import { Platforms } from './pages/Platforms';
import { Partner } from './pages/Partner';
import { IBCommission } from './pages/IBCommission';
import { WebTrader } from './pages/WebTrader';
import { Regulations } from './pages/Regulations';
import { Monstar } from './pages/Monstar';
import { Mam } from './pages/Mam';
import { Pamm } from './pages/Pamm';
import { ChristmasDraw } from './pages/ChristmasDraw';
import { XMasTicketPage } from './pages/XMasTicketPage';
import 'react-toastify/dist/ReactToastify.css';
import { HomeV2 } from './pages/HomeV2';
import StorePage from './pages/StorePage';
import LayoutV2 from './layout/layoutV2';
import ChooseProduct from './pages/campaign-pages/ChooseProduct';
import AccountChange from './pages/campaign-pages/AccountChange';
import FirstTrade from './pages/campaign-pages/FirstTrade';
import LossRefund from './pages/campaign-pages/LossRefund';
import PammMam from './pages/campaign-pages/PammMam';
import SwapDay from './pages/campaign-pages/SwapDay';
import MonsCash from './pages/campaign-pages/MonsCash';
import CopyTrade from './pages/campaign-pages/CopyTrade';
import MonsCredit from './pages/campaign-pages/MonsCredit';
import TradeRefund from './pages/campaign-pages/TradeRefund';
library.add(fab);
library.add(far);
library.add(fas);

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutV2 />}>
          <Route index element={<HomeV2 />} />
          <Route path="/privileges">
            <Route path="store" element={<StorePage />}></Route>
          </Route>
          <Route path="/campaign">
            <Route path="choose-product" element={<ChooseProduct />}></Route>
            <Route path="account-change" element={<AccountChange />}></Route>
            <Route path="first-trade" element={<FirstTrade />}></Route>
            <Route path="loss-refund" element={<LossRefund />}></Route>
            <Route path="pamm-n-mam" element={<PammMam />}></Route>
            <Route path="swap-day" element={<SwapDay />}></Route>
            <Route path="mons-cash" element={<MonsCash />}></Route>
            <Route path="copy-trade" element={<CopyTrade />}></Route>
            <Route path="mons-credit" element={<MonsCredit />}></Route>
            <Route path="trade-refund" element={<TradeRefund />}></Route>
          </Route>
          <Route path="/accounts">
            <Route path='trading'>
              <Route path="monstar" element={<Monstar />} />
            </Route>
          </Route>
          {/* <Route path="christmas-draw" element={<ChristmasDraw />}></Route> */}
          <Route path='/trading'>
            <Route path="trading-instrument">
              <Route path="forex" element={<ForexPage />}></Route>
              <Route path="indices" element={<IndicesPage />}></Route>
              <Route path="metals" element={<MetalsPage />}></Route>
              <Route path="energies" element={<EnergiesPage />}></Route>
              <Route path="crypto" element={<CryptoPage />}></Route>
              <Route path="stocks" element={<StocksPage />}></Route>
            </Route>
            <Route path="trading-tools">
              <Route path='economic-calender' element={<EconomicCalender />} />
              <Route path='forex-news' element={<ForexNews />} />
              <Route path='leverage' element={<Leverage />} />
              <Route path='calculator' element={<Calculator />} />
            </Route>
            <Route path="pricing">
              <Route path='spreads' element={<Spreads />} />
              <Route path='swap-rates' element={<SwapRates />} />
              <Route path='trading-hours' element={<TradingHours />} />
              <Route path='holiday-trading-hours' element={<HolidayTradingHours />} />
            </Route>
          </Route>
          <Route path="/accounts">
            <Route path='trading'>
              <Route path="demo-account" element={<Accounts />} />
              <Route path="cent-account" element={<Accounts />} />
              <Route path="classic-account" element={<Accounts />} />
              <Route path="prime-account" element={<Accounts />} />
              <Route path="ecn-account" element={<Accounts />} />
              <Route path="islamic-account" element={<Accounts />} />
            </Route>
            <Route path="education">
              <Route path="video-tutorials" element={<VideoTutorial />} />
              <Route path="e-books" element={<EBooksPage />} />
            </Route>
          </Route>
          <Route path='/platforms'>
            <Route path='computer'>
              <Route path='mt5-for-pc' element={<Platforms />} />
              <Route path='mt5-for-mac' element={<Platforms />} />
              <Route path='mt5-for-web' element={<Platforms />} />
            </Route>
            <Route path='phone'>
              <Route path='mt5-for-apple' element={<Platforms />} />
              <Route path='mt5-for-android' element={<Platforms />} />
            </Route>
            <Route path='tablet'>
              <Route path='mt5-for-ipad' element={<Platforms />} />
              <Route path='mt5-for-android' element={<Platforms />} />
            </Route>
          </Route>
          <Route path="/promotions">
            <Route path="cashback" element={<Cashback />}></Route>
            <Route path="trading-bonus" element={<TradingBonus />}></Route>
            <Route path="zero-commission" element={<ZeroCommission />}></Route>
            <Route path="trade-demo" element={<TradeDemo />}></Route>
            <Route path="invite-a-friend" element={<InviteAFriend />}></Route>
            <Route path="account-transfer" element={<AccountTransfer />}></Route>
            <Route path="vip" element={<Vip />}></Route>
          </Route>
          <Route path='/about'>
            <Route path='company'>
              <Route path='about-us' element={<AboutUs />} />
              {/* <Route path='license-and-registration' element={<LicenseAndRegistration />} /> */}
              <Route path='documentation' element={<Documentation />} />
              <Route path='regulations' element={<Regulations />} />
            </Route>
            <Route path='contact-us'>
              <Route path='faq' element={<FAQ />} />
              <Route path='contact' element={<ContactUs />} />
            </Route>
          </Route>
          <Route path='/partnerships' >
            <Route path='for-partners' >
              <Route path='partner' element={<Partner />} />
            </Route>
            <Route path='services'>
              <Route path='ib-commission' element={<IBCommission />} />
              <Route path='mam' element={<Mam />} />
              <Route path='pamm' element={<Pamm />} />
            </Route>
          </Route>
        </Route>

        <Route path="/" element={<LayoutWebsite />}>
          {/* <Route path="/v2" element={<HomeV2 />}></Route> */}




          {/* <Route path="/special">
            <Route path="ticket-page" element={<XMasTicketPage />}></Route>
          </Route> */}
          {/* <Route path="/login" element={<LoginPage />}></Route>
            <Route path="/open-account" element={<OpenAccount />}></Route> */}


          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
          <Route path="/cookie-policy" element={<CookiePolicy />}></Route>
          <Route path="/aml-policy" element={<AMLPolicy />}></Route>
          <Route path='/web-trader' element={<WebTrader />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
